div.socialmedia {
  width: 30%;
  margin: 3rem auto;
  text-align: center;
  @media screen and (max-width: 900px) {
    h1 {
      font-size: 25px;
    }
    width: 90%;
  }
  div.list {
    display: flex;
    justify-content: center;
    a {
      --icon-size: 60px;
      text-decoration: none;
      background-size: contain;
      width: var(--icon-size);
      height: var(--icon-size);
      margin: 1rem 2rem;
      transition: 300ms;
      border-radius: 100%;
      &:hover {
        scale: 1.2;
        box-shadow: 0 0 20px #acacac;
      }
      &.facebook {
        background-image: url(./Images/f_logo_RGB-Blue_1024.png);
      }
      &.instagram {
        border-radius: 25%;
        background-image: url(./Images/Instagram_Glyph_Gradient.png);
      }
    }
  }
}
