div.contact {
  a {
    display: block;
    text-align: left;
    text-decoration: none;
    color: #472c3a;
    img.icon {
      display: inline-block;
      height: 20px;
      transform: translateY(2px);
      margin-right: 10px;
    }
  }
}
