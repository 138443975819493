@font-face {
  font-family: BICKHAM-SCRIPT-PRO-REGULAR;
  src: url(./Assets/BICKHAM-SCRIPT-PRO-REGULAR.TTF);
}

a.logo {
  user-select: none;
  display: inline-block;
  font-family: BICKHAM-SCRIPT-PRO-REGULAR;
  font-size: 35px;
  color: #472c3a;
  text-decoration: none;
  transition: 300ms;
  background-color: #d781ae;
  width: max-content;
  padding: 1rem;
  height: 50px;
  line-height: 50px;
  line-height: 25px;
  // Mobile view
  @media screen and (max-width: 900px) {
    height: max-content;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
a.logo:hover {
  filter: brightness(85%);
}
