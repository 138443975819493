div.openinghours {
  p.active {
    background-color: #c06091;
    padding: 0.3rem;
    color: #100901;
    border-radius: 10px;
    align-self: center;
    transform: translateX(-0.3rem);
  }
}
