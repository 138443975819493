div.creator {
  color: #472c3a;
  padding: 0.5rem;
  a {
    text-transform: uppercase;
    font-family: "Audiowide";
    text-decoration: underline 0px transparent;
    transition: 300ms;
    color: #472c3a;
  }
  a:hover {
    text-decoration: underline 5px #472c3a;
  }
}
