div.gallery {
  position: relative;
  height: 60vh;
  img {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    // Mobile view
    @media screen and (max-width: 900px) {
      width: 60%;
    }
    // Desktop view
    @media screen and (min-width: 900px) {
      height: 80%;
    }
  }
}
