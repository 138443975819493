footer {
  background-color: #d781ae;
  color: white;
  text-align: center;
  padding: 0.5rem;
  position: relative;
  align-items: center;
  & > div.info {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    div.box {
      color: #472c3a;
      margin-top: 1rem;
      line-height: 1.5rem;
      padding: 1rem;
      width: max-content;
      p.title {
        font-weight: bold;
        margin-bottom: 0.2rem;
      }
      p:not(.title) {
        text-align: left;
      }

      @media screen and (min-width: 900px) {
        padding: 2rem 4rem;
      }
    }
  }
}
