div.gallery {
  div.button {
    position: absolute;
    cursor: pointer;
    transition: 300ms;
    user-select: none;
    z-index: 1;
    top: 50%;
    padding: 1rem;
    transform: translateY(-50%);
    background-color: #d3d3d38c;

    &:last-child {
      right: 0%;
      transform: translateY(-50%) rotate(180deg);
      // desktop view
      @media screen and (min-width: 900px) {
        right: 40%;
      }
    }
    &:first-child {
      // desktop view
      @media screen and (min-width: 900px) {
        left: 40%;
      }
    }
    &:hover {
      filter: brightness(85%);
    }
    &:active {
      filter: brightness(70%);
    }
    div.arrow {
      width: 20px;
      height: 20px;
      border-width: 5px 0px 0px 5px;
      border-color: #00000059 transparent transparent #00000059;
      border-style: solid;
      transform: rotate(-45deg);
    }
  }
}
