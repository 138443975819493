div.hamburger {
  width: 25px;
  height: 25px;
  margin: 1rem;
  opacity: 0;
  cursor: pointer;
  & > div {
    background-color: #472c3a;
    width: 100%;
    height: 5px;
    transition: 300ms;
    border-radius: 5px;
  }
  & > div:not(:first-child) {
    margin-top: 5px;
  }
  &.active > div:first-child {
    transform: translateY(200%) rotate(45deg);
  }
  &.active > div:nth-child(2) {
    opacity: 0;
  }
  &.active > div:last-child {
    transform: translateY(-200%) rotate(-45deg);
  }

  @media screen and (min-width: 900px) {
    display: none;
  }
}
