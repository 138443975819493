div.about-business {
  margin: 1rem auto;
  width: 85%;
  text-align: center;
  p {
    margin: 1rem auto;
    line-height: 200%;
  }
  @media screen and (min-width: 900px) {
    width: 30%;
    p {
      margin: 2rem auto;
    }
  }
}
